<template>
  <CModal
    :show="show"
    variant="fullpagesheet"
    header-class="container no-spacing is-small"
    footer-class="md:hidden"
    left-close-button
    submit-form="product-container-create-update-form"
    @toggle="onToggle"
  >
    <template v-slot:header>
      <template v-if="updateMode">
        {{ $t('products.container.update') }}
      </template>
      <template v-else>
        {{ $t('products.container.add') }}
      </template>
    </template>
    <template v-slot:header-right>
      <div v-if="updateMode" class="inline-flex mr-2">
        <CButton
          v-if="productForm.deleted === false"
          type="button"
          icon-left="trash-outline"
          size="small"
          variant="danger"
          inverted
          @click="deleteProduct"
        >
          {{ $t('delete') }}
        </CButton>
      </div>
      <div class="hidden md:flex">
        <CButton
          v-if="!updateMode"
          native-type="submit"
          class="mr-2"
          size="small"
          form="product-container-create-update-form"
          :loading="submitLoading && submitType === 'submitAndReset'"
          @click="submitAndCreateNew"
        >
          {{ $t('products.container.saveAndCreateAnother') }}
        </CButton>
        <CButton
          variant="primary"
          native-type="submit"
          form="product-container-create-update-form"
          size="small"
          :loading="submitLoading && submitType === 'submitAndClose'"
          @click="checkFormValidity"
        >
          <template v-if="updateMode">
            {{ $t('save') }}
          </template>
          <template v-else>
            {{ $t('create') }}
          </template>
        </CButton>
      </div>
    </template>
    <template v-slot:content>
      <div v-if="!loading" class="py-4 md:py-8 container is-tiny">
        <CAlert
          v-if="!canUpdateProduct && productForm.deleted === false"
          variant="warning"
          highlight
        >
          {{ $t('products.container.existingOrders') }}
          <template v-slot:content>
            {{ $t('products.container.cannotUpdateProductExistingOrders') }}
          </template>
          <template v-slot:links>
            <CLink
              tstyle="body2"
              inverted
              @click="resetAndOpenOther"
            >
              {{ $t('products.container.createNewInstead') }} ->
            </CLink>
          </template>
        </CAlert>
        <CAlert v-else-if="productForm.deleted" variant="warning" highlight>
          {{ $t('products.container.isDeleted') }}
          <template v-slot:content>
            {{ $t('products.container.canReactivateContainer') }}
          </template>
          <template v-slot:links>
            <CLink
              tstyle="body2"
              inverted
              @click="reactivate"
            >
              {{ $t('reactivate') }} ->
            </CLink>
          </template>
        </CAlert>
        <CAlert v-if="containerWithSizeAndTypeExistsError" variant="danger" highlight>
          {{ $t('conflict') }}
          <template v-slot:content>
            {{ $t('products.container.containerWithSizeAndTypeExists', {
              type: $t(`products.container.types.${productForm.container.type}`),
              size: productForm.container.size,
            }) }}
          </template>
          <template v-slot:links>
            <CLink
              tstyle="body2"
              inverted
              @click="resetAndOpenOther(containerWithSizeAndTypeExistsError)"
            >
              {{ $t('products.container.update') }} ->
            </CLink>
          </template>
        </CAlert>
        <CForm
          id="product-container-create-update-form"
          @submit="onSubmit"
          custom-save-button
          :error-fields-response="errorFieldsResponse"
        >
          <div class="grid md:grid-cols-2 gap-4 mb-4">
            <CSelectBox
              v-model="productForm.container.type"
              native-value="dropoff"
              name="containerType"
              :disabled="!canUpdateProduct"
              required
            >
              <div class="w-20 h-20 rounded-lg overflow-hidden mb-2 flex justify-center">
                <CContainerImage 
                  :show-default="productForm.container.type !== 'dropoff'"
                  type="dropoff" 
                  :flap="productForm.container.flap"
                  :cover="productForm.container.cover"
                  :doors="productForm.container.doors"
                  :size="
                    productForm.container.size ? 
                      germanDecimalToNumber(productForm.container.size) : 0
                  "
                />
              </div>
              <CTypo tstyle="subheadline">{{ $t('products.container.types.dropoff') }}</CTypo>
            </CSelectBox>
            <CSelectBox
              v-model="productForm.container.type"
              native-value="rolloff"
              name="containerType"
              :disabled="!canUpdateProduct"
            >
              <div class="w-20 h-20 rounded-lg overflow-hidden mb-2 flex justify-center">
                <CContainerImage 
                  :show-default="productForm.container.type !== 'rolloff'"
                  type="rolloff" 
                  :flap="productForm.container.flap"
                  :cover="productForm.container.cover"
                  :doors="productForm.container.doors"
                  :size="
                    productForm.container.size ? 
                      germanDecimalToNumber(productForm.container.size) : 0
                  "
                />
              </div>
              <CTypo tstyle="subheadline">{{ $t('products.container.types.rolloff') }}</CTypo>
            </CSelectBox>
          </div>
          <CTextField
            v-model="productForm.container.size"
            :label="$t('containerSize')"
            :mask="containerMask"
            :disabled="!canUpdateProduct"
            required
          />
          <CTextField
            v-model="productForm.number"
            :label="$t('products.container.number')"
            type="number"
            step="1"
            min="1"
            required
            :message="$t('products.container.availableNumber')"
          />

          <div v-if="productForm.container.type">
            <CTypo
              v-if="productForm.container.type !== 'bigbag'"
              tstyle="caption1"
              class="mb-3"
              el="div"
            >
              {{ $t('products.container.openingType') }}
            </CTypo>
            <CSwitch
              class="mr-3" 
              v-if="
                productForm.container.type !== 'rolloff' && 
                  productForm.container.type !== 'bigbag'
              " 
              v-model="productForm.container.flap"
              :disabled="
                !canUpdateProduct || 
                  productForm.container.type === 'dropoff' && productForm.container.doors
              "
            >
              {{ $t('products.container.flap') }}
            </CSwitch>
            <CSwitch
              class="mr-3" 
              v-if="productForm.container.type !== 'bigbag'" 
              v-model="productForm.container.cover"
              :disabled="!canUpdateProduct"
            >
              {{ $t('products.container.cover') }}
            </CSwitch>
            <CSwitch
              class="mr-3" 
              v-if="
                productForm.container.type !== 'rolloff' && 
                  productForm.container.type !== 'bigbag'
              " 
              v-model="productForm.container.doors"
              :disabled="
                !canUpdateProduct || 
                  productForm.container.type === 'dropoff' && productForm.container.flap
              "
            >
              {{ $t('products.container.doors') }}
            </CSwitch>

            <CCollapse v-model="collapseOpen">
              <template v-slot:title>
                {{ $t('products.container.sizeInformation.containerSizeInformation') }}
              </template>
              <CTextField
                v-model="productForm.container.length"
                :label="$t('products.container.sizeInformation.length')"
                type="number"
                step="1"
                min="1"
                required
                :disabled="!canUpdateProduct"
              />
              <CTextField
                v-model="productForm.container.width"
                :label="$t('products.container.sizeInformation.width')"
                type="number"
                step="1"
                min="1"
                required
                :disabled="!canUpdateProduct"
              />
              <CTypo tstyle="caption1" class="mb-3" el="div">
                {{ $t('products.container.sizeInformation.vehicleSizeInformation') }}
              </CTypo>
              <CTextField
                v-model="productForm.container.vehiclePassageWidth"
                :label="$t('products.container.sizeInformation.vehicle.passageWidth')"
                type="number"
                step="1"
                min="1"
                required
                :disabled="!canUpdateProduct"
              />
              <CTextField
                v-model="productForm.container.vehicleHeadroom"
                :label="$t('products.container.sizeInformation.vehicle.headroom')"
                type="number"
                step="1"
                min="1"
                required
                :disabled="!canUpdateProduct"
              />
              <CTextField
                v-model="productForm.container.vehicleManeuveringAreaLength"
                :label="$t('products.container.sizeInformation.vehicle.maneuveringAreaLength')"
                type="number"
                step="1"
                min="1"
                required
                :disabled="!canUpdateProduct"
              />
              <CTextField
                v-model="productForm.container.vehicleManeuveringAreaWidth"
                :label="$t('products.container.sizeInformation.vehicle.maneuveringAreaWidth')"
                type="number"
                step="1"
                min="1"
                required
                :disabled="!canUpdateProduct"
              />
              <CTextField
                v-model="productForm.container.vehicleLength"
                :label="$t('products.container.sizeInformation.vehicle.length')"
                type="number"
                step="1"
                min="1"
                required
                :disabled="!canUpdateProduct"
              />
              <CTextField
                v-model="productForm.container.vehicleOverallLength"
                :label="$t('products.container.sizeInformation.vehicle.overallLength')"
                type="number"
                step="1"
                min="1"
                required
                :disabled="!canUpdateProduct"
              />
              <CTextField
                v-model="productForm.container.vehicleTotalWeight"
                :label="$t('products.container.sizeInformation.vehicle.totalWeight')"
                type="number"
                step="1"
                min="1"
                required
                :disabled="!canUpdateProduct"
              />
            </CCollapse>
          </div>

          <div class="pt-4">
            <CTypo tstyle="caption1" class="mb-3" el="div">
              {{ $t('products.container.sellingPrice') }}
            </CTypo>
            <CAlert
              variant="info"
              highlight
            >
              {{ $t('allPricesWithoutTaxesAndServiceFee') }}
            </CAlert>
            <CTextField
              v-model="sellingPriceForm.basePrice"
              :label="$t('basePrice')"
              :mask="currencyMask"
              icon="currency-euro"
              :message="$t('basePriceHelpMessage')"
              select-all-on-focus
            />
            <CTextField
              v-model="sellingPriceForm.containerSellingPrice.durationPriceAfter"
              :label="$t('products.container.durationPriceAfter')"
              type="number"
              step="1"
              min="0"
              required
            />
            <CTextField
              v-model="sellingPriceForm.containerSellingPrice.durationPricePerDay"
              :label="$t('products.container.durationPricePerDay')"
              :mask="currencyMask"
              icon="currency-euro"
              required
              select-all-on-focus
            />
            <CTextField
              v-model="sellingPriceForm.containerSellingPrice.pricePerDeliveryKm"
              :label="$t('products.container.pricePerDeliveryKm')"
              :mask="currencyMask"
              icon="currency-euro"
              required
              :message="$t('products.container.pricePerDeliveryKmDescription')"
              select-all-on-focus
            />
          </div>
        </CForm>
      </div>
      <div v-else class="h-[450px] flex items-center justify-center">
        <CLoadingSpinner class="w-8 h-8" />
      </div>
    </template>
    <template v-slot:submit>
      <template v-if="updateMode">
        {{ $t('save') }}
      </template>
      <template v-else>
        {{ $t('create') }}
      </template>
    </template>
  </CModal>
</template>

<script>
import { mapActions } from 'vuex';
import {
  CREATE_PRODUCT_CONTAINER,
  CREATE_SELLING_PRICE,
  GET_PRODUCT,
  UPDATE_PRODUCT_CONTAINER,
  UPDATE_SELLING_PRICE,
} from '@/store/actionTypes';
import { MASK_CONTAINER_SIZE, MASK_CURRENCY } from '@/constants/ui';
import germanDecimalParser, { germanDecimalToNumber } from '@/utils/germanDecimalParser';
import {
  diffPricingChanges,
  setUpdateProduct,
  updateOrCreateSellingPrice,
} from './CreateUpdateModal';

export default {
  model: {
    prop: 'show',
    event: 'toggle',
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    updateId: {
      type: Number,
      default: null,
    },
  },

  data: () => ({
    submitType: null,
    productForm: {
      type: 'container',
      number: 1,
      container: {
        size: null,
        type: null,
        flap: false,
        cover: false,
        doors: false,
        length: 400,
        width: 200,
        vehiclePassageWidth: 300,
        vehicleHeadroom: 410,
        vehicleManeuveringAreaLength: 1400,
        vehicleManeuveringAreaWidth: 400,
        vehicleLength: 850,
        vehicleOverallLength: 1000,
        vehicleTotalWeight: 26000,
      },
    },
    sellingPriceForm: {
      name: null,
      basePrice: 0,
      containerSellingPrice: {
        durationPriceAfter: 14,
        durationPricePerDay: 0,
        pricePerDeliveryKm: 0,
      },
    },
    currentSellingPrice: {
      name: null,
      basePrice: 0,
      containerSellingPrice: {
        durationPriceAfter: 14,
        durationPricePerDay: 0,
        pricePerDeliveryKm: 0,
      },
    },
    loading: false,
    canUpdateProduct: true,
    deleteDialog: false,
    containerWithSizeAndTypeExistsError: false,
    shouldUpdateSellingPrice: false,
    useDefaultSizeInformation: true,
    collapseOpen: false,
    errorFieldsResponse: null,
  }),

  computed: {
    submitLoading() {
      return this.$store.state.products.submitLoading;
    },
    updateMode() {
      if (this.updateId && this.updateId > 0) return true;
      return false;
    },
    containerMask() {
      return MASK_CONTAINER_SIZE;
    },
    currencyMask() {
      return MASK_CURRENCY;
    },
  },

  watch: {
    'show': {
      immediate: true,
      handler() {
        this.getUpdateProduct();
      },
    },
    'sellingPriceForm': {
      deep: true,
      handler(newVal) {
        if (this.show && !this.loading && newVal) {
          diffPricingChanges(this, newVal);
        }
        this.errorFieldsResponse = null;
      },
    },
    // eslint-disable-next-line func-names
    'productForm.container.type': function (newVal) {
      if (this.loading === false) {
        if (newVal === 'dropoff') {
          this.productForm.container.length = 400;
          this.productForm.container.width = 200;
        } else if (newVal === 'rolloff') {
          this.productForm.container.length = 600;
          this.productForm.container.width = 250;
        } else if (newVal === 'bigbag') {
          this.productForm.container.length = 100;
          this.productForm.container.width = 100;
        }
        this.productForm.container.flap = false;
        this.productForm.container.cover = false;
        this.productForm.container.doors = false;
      }
    },
    // eslint-disable-next-line func-names
    'productForm.container.flap': function (newVal) {
      if (
        this.productForm.container.type === 'dropoff' &&
        this.productForm.container.doors &&
        newVal
      ) {
        this.productForm.container.doors = false;
      }
    },
    // eslint-disable-next-line func-names
    'productForm.container.doors': function (newVal) {
      if (
        this.productForm.container.type === 'dropoff' &&
        this.productForm.container.flap &&
        newVal
      ) {
        this.productForm.container.flap = false;
      }
    },
  },

  methods: {
    ...mapActions([
      GET_PRODUCT,
      CREATE_PRODUCT_CONTAINER,
      UPDATE_PRODUCT_CONTAINER,
      CREATE_SELLING_PRICE,
      UPDATE_SELLING_PRICE,
    ]),
    getUpdateProduct() {
      if (this.updateMode && this.show) {
        this.loading = true;
        this.getProduct(this.updateId).then((data) => {
          setUpdateProduct(this, data);
        });
      } else {
        this.loading = false;
        this.reset();
      }
    },
    onToggle(show) {
      this.$emit('toggle', show);
      if (!show) {
        this.$emit('close');
      }
    },
    submitAndCreateNew() {
      this.submitType = 'submitAndReset';
      this.checkFormValidity();
    },
    reset() {
      this.productForm = {
        type: 'container',
        number: 1,
        container: {
          size: null,
          type: null,
          flap: false,
          cover: false,
          doors: false,
          length: 400,
          width: 200,
          vehiclePassageWidth: 300,
          vehicleHeadroom: 410,
          vehicleManeuveringAreaLength: 1400,
          vehicleManeuveringAreaWidth: 400,
          vehicleLength: 850,
          vehicleOverallLength: 1000,
          vehicleTotalWeight: 26000,
        },
      };
      this.canUpdateProduct = true;
      const { durationPriceAfter, durationPricePerDay, pricePerDeliveryKm } =
        this.sellingPriceForm.containerSellingPrice;
      this.sellingPriceForm = {
        name: null,
        basePrice: 0,
        containerSellingPrice: {
          durationPriceAfter: durationPriceAfter || 14,
          durationPricePerDay: durationPricePerDay || 0,
          pricePerDeliveryKm: pricePerDeliveryKm || 0,
        },
      };
      this.containerWithSizeAndTypeExistsError = false;
      this.shouldUpdateSellingPrice = false;
    },
    resetAndOpenOther(productId = null) {
      this.loading = true;
      this.reset();
      this.$emit('update:updateId', productId);
      setTimeout(() => {
        this.loading = false;
        this.getUpdateProduct();
      }, 300);
    },
    async onSubmit() {
      if (this.submitType === null) {
        this.submitType = 'submitAndClose';
      }
      try {
        this.productForm.container.size = germanDecimalToNumber(this.productForm.container.size);
        if (this.updateMode) {
          const { id, ...productForm } = this.productForm;
          try {
            await this.updateProductContainer({
              id,
              body: {
                type: productForm.type,
                number: Number(productForm.number),
                container: {
                  size: Number(this.productForm.container.size),
                  type: this.productForm.container.type,
                  flap: this.productForm.container.flap,
                  cover: this.productForm.container.cover,
                  doors: this.productForm.container.doors,
                  length: Number(this.productForm.container.length),
                  width: Number(this.productForm.container.width),
                  vehiclePassageWidth: Number(this.productForm.container.vehiclePassageWidth),
                  vehicleHeadroom: Number(this.productForm.container.vehicleHeadroom),
                  vehicleManeuveringAreaLength: Number(
                    this.productForm.container.vehicleManeuveringAreaLength,
                  ),
                  vehicleManeuveringAreaWidth: Number(
                    this.productForm.container.vehicleManeuveringAreaWidth,
                  ),
                  vehicleLength: Number(this.productForm.container.vehicleLength),
                  vehicleOverallLength: Number(this.productForm.container.vehicleOverallLength),
                  vehicleTotalWeight: Number(this.productForm.container.vehicleTotalWeight),
                },
              },
            });
          } catch (e) {
            if (e.response.data.code === 'E_VALIDATION_FAILURE') {
              this.errorFieldsResponse = e.response;
              return;
            }
            throw e;
          }
          try {
            await updateOrCreateSellingPrice(this);
          } catch (e) {
            if (e.response.data.code === 'E_VALIDATION_FAILURE') {
              this.errorFieldsResponse = e.response;
              return;
            }
            throw e;
          }
        } else {
          const { durationPriceAfter, durationPricePerDay, pricePerDeliveryKm } =
            this.sellingPriceForm.containerSellingPrice;
          const body = {
            type: this.productForm.type,
            number: Number(this.productForm.number),
            container: {
              size: Number(this.productForm.container.size),
              type: this.productForm.container.type,
              flap: this.productForm.container.flap,
              cover: this.productForm.container.cover,
              doors: this.productForm.container.doors,
              length: Number(this.productForm.container.length),
              width: Number(this.productForm.container.width),
              vehiclePassageWidth: Number(this.productForm.container.vehiclePassageWidth),
              vehicleHeadroom: Number(this.productForm.container.vehicleHeadroom),
              vehicleManeuveringAreaLength: Number(
                this.productForm.container.vehicleManeuveringAreaLength,
              ),
              vehicleManeuveringAreaWidth: Number(
                this.productForm.container.vehicleManeuveringAreaWidth,
              ),
              vehicleLength: Number(this.productForm.container.vehicleLength),
              vehicleOverallLength: Number(this.productForm.container.vehicleOverallLength),
              vehicleTotalWeight: Number(this.productForm.container.vehicleTotalWeight),
            },
            sellingPrice: {
              name: null,
              basePrice: germanDecimalToNumber(this.sellingPriceForm.basePrice),
              containerSellingPrice: {
                durationPriceAfter: germanDecimalToNumber(durationPriceAfter),
                durationPricePerDay: germanDecimalParser(durationPricePerDay),
                pricePerDeliveryKm: germanDecimalParser(pricePerDeliveryKm),
              },
            },
          };
          try {
            await this.createProductContainer(body);
          } catch (e) {
            if (e.response.data.code === 'E_VALIDATION_FAILURE') {
              this.errorFieldsResponse = e.response;
              return;
            }
            throw e;
          }
        }
        if (this.submitType === 'submitAndClose') {
          this.onToggle(false);
        } else {
          this.reset();
        }
        this.submitType = null;
      } catch (e) {
        if (e.response.data.code === 'E_CONTAINER_WITH_SIZE_AND_TYPE_ALREADY_EXISTS') {
          const existingId = e.response.data.productId;
          this.containerWithSizeAndTypeExistsError = Number(existingId);
        }
        this.submitType = null;
      }
    },
    deleteProduct() {
      this.$emit('delete', {
        id: this.updateId,
      });
    },
    async reactivate() {
      const { id, ...productForm } = this.productForm;
      await this.updateProductContainer({
        id,
        body: {
          type: 'container',
          number: productForm.number,
          deleted: false,
          container: {},
        },
      });
      this.getUpdateProduct();
    },
    checkFormValidity() {
      const form = this.$el.querySelector('#product-container-create-update-form');
      this.collapseOpen = !form.checkValidity();
    },
    germanDecimalToNumber(val) {
      return germanDecimalToNumber(val);
    },
  },
};
</script>
